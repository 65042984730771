import * as React from "react"

import Layout from "../components/layout"
import Office from "../components/office"

import joannagalczynska from "../images/joanna_galczynska.jpeg"
import martynakozlowskawytyk from "../images/martyna_kozlowska_wytyk.jpeg"
import witoldpracki from "../images/witold_pracki.jpeg"
import grazynaderezinskakozlowska from "../images/doctor.png"
import mariaczubswierczek from "../images/doctor.png"
import katarzynabernardczyk from "../images/doctor.png"
import walentychudzicki from "../images/doctor-man.png"



const IndexPage = () => {
  return (
    <Layout>
		<section
			className="elementor-section elementor-top-section elementor-element elementor-element-72e760b elementor-section-stretched dsvy-col-stretched-none dsvy-bg-color-over-image elementor-section-boxed elementor-section-height-default elementor-section-height-default dsvy-col-stretched-no dsvy-bgimage-no dsvy-bgcolor-yes">
			<div className="elementor-container elementor-column-gap-default">
				<div
					className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-018fa56 dsvy-bg-color-over-image dsvy-bgimage-no dsvy-bgcolor-yes"
					chirurg-onkolog>
					<div className="elementor-widget-wrap elementor-element-populated">
						<div
							className="elementor-element elementor-element-3b4fb49 elementor-widget elementor-widget-dsvy_team_element"
							data-widget_type="dsvy_team_element.default">
							<div className="elementor-widget-container">
								<div
									className="designervily-element designervily-element-team dsvy-element-team-style-1 designervily-element-viewtype-row-column designervily-gap-30px"
									data-center="false"
									data-margin="30px">
									<div className="designervily-element-inner">

										<div className="dsvy-ele-header-area">
											<div className="dsvy-heading-subheading center-align dsvy-reverse-heading-yes">
												<h4 className="dsvy-element-subtitle">Gabinety RASZYN</h4>
												<h2 className="dsvy-element-title">Nasi specjaliści</h2>
												<div className="dsvy-heading-desc">Gabinety RASZYN to zespół prywatnych
													gabinetów lekarskich zajmujący się diagnostyką, profilaktyką i leczeniem
													pacjentów. Kompetencje doświadczonego personelu medycznego oraz
													specjalistyczne wyposażenie gabinetów pozwalają na wysoki poziom
													świadczonych usług.<br />
													Nasi specjaliści obejmą Państwa opieką w zakresie:

													<section className="dsvy-specs elementor-section elementor-inner-section elementor-element dsvy-col-stretched-none dsvy-bg-color-over-image elementor-section-boxed elementor-section-height-default elementor-section-height-default">
														<div className="elementor-container elementor-column-gap-default elementor-container-specs">
															<div className="elementor-row">
																<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-6da977e4 dsvy-bg-color-over-image">
																	<div className="elementor-column-wrap elementor-element-populated">
																		<div className="elementor-widget-wrap">
																			<div className="elementor-element elementor-element-7d471cb6 elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
																				<div className="elementor-widget-container">
																					<ul className="elementor-icon-list-items">
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#ginekolog">Ginekologii</a></span>
																						</li>
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#kardiolog">Kardiologii</a></span>
																						</li>
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#okulista">Okulistyki</a></span>
																						</li>
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#chirurg-proktolog">Proktologii</a></span>
																						</li>
																					</ul>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
																<div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-1eb26abc dsvy-bg-color-over-image">
																	<div className="elementor-column-wrap elementor-element-populated">
																		<div className="elementor-widget-wrap">
																			<div className="elementor-element elementor-element-4acc595d elementor-icon-list--layout-traditional elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list">
																				<div className="elementor-widget-container">
																					<ul className="elementor-icon-list-items">
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#chirurg-proktolog">Chirurgii</a></span>
																						</li>
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#chirurg-onkolog">Chirurgii onkologicznej</a></span>
																						</li>
																						<li className="elementor-icon-list-item">
																							<span className="elementor-icon-list-icon">
																								<i aria-hidden="true" className="mdi mdi-check"></i>
																							</span>
																							<span className="elementor-icon-list-text"><a href="#chirurg-dzieciecy">Chirurgii dziecięcej</a></span>
																						</li>

																					</ul>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</section>
												</div>
											</div>
										</div>
										<div className="dsvy-element-posts-wrapper row multi-columns-row">

											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-5">
												<div className="designervily-post-item">
													<div className="designervily-team-image-box">
														<div className="dsvy-featured-wrapper">
															<a name="kardiolog" href="/kardiolog-poznan-joanna-galczynska">
																<img loading="lazy" width="770"
																	height="635"
																	src={joannagalczynska}
																	className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																	alt="Kardiolog Joanna Gałczyńska"/>
															</a>
														</div>
													</div>
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															<a href="/kardiolog-poznan-joanna-galczynska">
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Kardiolog</div>
																</div>
																<h3 className="dsvy-team-title">lek. Joanna Gałczyńska</h3>
															</a>
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														<br />
														<div className="designervily-box-social-links">
															<a href="/kardiolog-poznan-joanna-galczynska"><button type="submit" value="Więcej">Więcej</button></a>
														</div>
													</div>
												</div>
											</article>


											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-5">
												<div className="designervily-post-item">
													<div className="designervily-team-image-box">
															<div className="dsvy-featured-wrapper">
																<a name="ginekolog" href="/ginekolog-poznan-martyna-kozlowska-wytyk">
																	<img loading="lazy" width="770"
																		height="635"
																		src={martynakozlowskawytyk}
																		className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																		alt="Ginekolog Martyna Kozłowska-Wytyk"/>
																</a>
															</div>
													</div>
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															<a href="/ginekolog-poznan-martyna-kozlowska-wytyk">
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Ginekolog</div>
																</div>
																<h3 className="dsvy-team-title">lek. Martyna Kozłowska-Wytyk</h3>
															</a>
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														<br />
														<div className="designervily-box-social-links">
															<a href="/ginekolog-poznan-martyna-kozlowska-wytyk"><button type="submit" value="Więcej">Więcej</button></a>
														</div>
													</div>
												</div>
											</article>


											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-5">
												<a name="ginekolog"></a>
												<div className="designervily-post-item">
													{/* <div className="designervily-team-image-box">
															<div className="dsvy-featured-wrapper">
																<a name="ginekolog" href="/ginekolog-poznan-grazyna-derezinska-kozlowska">
																	<img loading="lazy" width="770"
																		height="635"
																		src={grazynaderezinskakozlowska}
																		className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																		alt="Ginekolog Grażyna Derezińska-Kozłowska"/>
																</a>
															</div>
													</div> */}
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															{/* <a href="/ginekolog-poznan-grazyna-derezinska-kozlowska"> */}
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Ginekolog</div>
																</div>
																<h3 className="dsvy-team-title">lek. Grażyna Derezińska-Kozłowska </h3>
															{/* </a> */}
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														{/* <br />
														<div className="designervily-box-social-links">
															<a href="/ginekolog-poznan-grazyna-derezinska-kozlowska"><button type="submit" value="Więcej">Więcej</button></a>
														</div> */}
													</div>
												</div>
											</article>


											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-5">
												<a name="chirurg-proktolog"></a>
												<div className="designervily-post-item">
													{/* <div className="designervily-team-image-box">
														<div className="dsvy-featured-wrapper">
															<a name="chirurg-proktolog" href="/chirurg-poznan-witold-pracki">
																<img loading="lazy" width="770"
																	height="635"
																	src={witoldpracki}
																	className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																	alt="Chirurg Proktolog Witold Pracki"/>
															</a>
														</div>
													</div> */}
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															{/* <a href="/chirurg-poznan-witold-pracki"> */}
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Chirurg, proktolog</div>
																</div>
																<h3 className="dsvy-team-title">dr n. med. Witold Pracki</h3>
															{/* </a> */}
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														{/* <br />
														<div className="designervily-box-social-links">
															<a href="/chirurg-poznan-witold-pracki"><button type="submit" value="Więcej">Więcej</button></a>
														</div> */}
													</div>
												</div>
											</article>


											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-4">
												<a name="chirurg-onkolog"></a>
												<div className="designervily-post-item">
													{/* <div className="designervily-team-image-box">
															<div className="dsvy-featured-wrapper">
																<a name="chirurg-onkolog" href="/chirurg-onkolog-poznan-maria-czub-swierczek">
																	<img loading="lazy" width="770"
																		height="635"
																		src={mariaczubswierczek}
																		className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																		alt="Onkolog, chirurg Maria Czub-Świerczek"/>
																</a>
															</div>
													</div> */}
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															{/* <a href="/chirurg-onkolog-poznan-maria-czub-swierczek"> */}
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Chirurg onkolog</div>
																</div>
																<h3 className="dsvy-team-title">lek. Maria Czub-Świerczek</h3>
															{/* </a> */}
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														{/* <br />
														<div className="designervily-box-social-links">
															<a href="/chirurg-onkolog-poznan-maria-czub-swierczek"><button type="submit" value="Więcej">Więcej</button></a>
														</div> */}
													</div>
												</div>
											</article>
											

											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-4">
												<a name="chirurg-dzieciecy"></a>
												<div className="designervily-post-item">
													{/* <div className="designervily-team-image-box">
															<div className="dsvy-featured-wrapper">
																<a name="chirurg-dzieciecy" href="/chirurg-dzieciecy-poznan-katarzyna-bernardczyk">
																	<img loading="lazy" width="770"
																		height="635"
																		src={katarzynabernardczyk}
																		className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																		alt="Chirurg onkolog Maria Czub-Świerczek"/>
																</a>
															</div>
													</div> */}
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															{/* <a href="/chirurg-dzieciecy-poznan-katarzyna-bernardczyk"> */}
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Chirurg dziecięcy</div>
																</div>
																<h3 className="dsvy-team-title">dr n. med. Katarzyna Bernardczyk</h3>
															{/* </a> */}
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														{/* <br />
														<div className="designervily-box-social-links">
															<a href="/chirurg-dzieciecy-poznan-katarzyna-bernardczyk"><button type="submit" value="Więcej">Więcej</button></a>
														</div> */}
													</div>
												</div>
											</article>


											<article className="dsvy-ele dsvy-ele-team dsvy-team-style-1 col-md-4">
												<div className="designervily-post-item">
													<a name="okulista"></a>
													{/* <div className="designervily-team-image-box">
															<div className="dsvy-featured-wrapper">
																<a name="okulista" href="/okulista-poznan-walenty-chudzicki">
																	<img loading="lazy" width="770"
																		height="635"
																		src={walentychudzicki}
																		className="attachment-dsvy-img-770x635 size-dsvy-img-770x635 wp-post-image"
																		alt="Okulista Walenty Chudzicki"/>
																</a>
															</div>
													</div> */}
													<div className="designervily-box-content">
														<div className="designervily-box-content-inner">
															{/* <a href="/okulista-poznan-walenty-chudzicki"> */}
																<div className="designervily-box-team-position">
																	<div className="designervily-box-team-position">Okulista</div>
																</div>
																<h3 className="dsvy-team-title">dr n. med. Walenty Chudzicki</h3>
															{/* </a> */}
														</div>
														<div className="designervily-box-content-inner">
															<a href="tel:618-610-123">Umów wizytę: <i className="dsvy-base-icon-call-1"></i>618-610-123</a>
														</div>
														{/* <br />
														<div className="designervily-box-social-links">
															<a href="/okulista-poznan-walenty-chudzicki"><button type="submit" value="Więcej">Więcej</button></a>
														</div> */}
													</div>
												</div>
											</article>
											
										</div>


									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<Office></Office>
    </Layout>          
  )
}

export default IndexPage
